/* src/components/Pages/Services.css */

/* Services Page Styles */
.services-container {
  font-family: 'Poppins', sans-serif;
  color: #333;
}

/* Hero Section */
.services-hero {
  position: relative;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  background-image: url('https://images.unsplash.com/photo-1533777857889-4be7c70b33f7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fGNhdGVyaW5nfGVufDB8fDB8fHww&auto=format&fit=crop&w=1600&q=60');
  background-size: cover;
  background-position: center;
  margin-bottom: 60px;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.7));
}

.hero-content {
  position: relative;
  z-index: 1;
  max-width: 800px;
  padding: 0 20px;
}

.hero-content h1 {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.hero-content p {
  font-size: 1.3rem;
  opacity: 0.9;
  margin: 0;
}

/* Introduction Section */
.services-intro {
  max-width: 1000px;
  margin: 0 auto 60px;
  text-align: center;
  padding: 0 20px;
}

.services-intro h2 {
  font-size: 2.2rem;
  color: #222;
  margin-bottom: 20px;
  font-weight: 700;
  position: relative;
  display: inline-block;
}

.services-intro h2:after {
  content: '';
  position: absolute;
  width: 80px;
  height: 3px;
  background-color: #FFD700;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.services-intro p {
  color: #555;
  font-size: 1.1rem;
  line-height: 1.6;
  margin-top: 25px;
}

/* Services Grid Section */
.services-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto 80px;
  padding: 0 20px;
}

.service-card {
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.service-image {
  height: 250px;
  overflow: hidden;
}

.service-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.service-card:hover .service-image img {
  transform: scale(1.05);
}

.service-content {
  padding: 25px;
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.service-icon {
  position: absolute;
  top: -25px;
  right: 25px;
  width: 50px;
  height: 50px;
  background-color: #FFD700;
  color: #000;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.service-content h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #222;
  font-weight: 600;
}

.service-content p {
  color: #666;
  line-height: 1.6;
  margin-bottom: 20px;
}

.service-features {
  list-style: none;
  padding: 0;
  margin-top: auto;
}

.service-features li {
  position: relative;
  padding-left: 20px;
  margin-bottom: 8px;
  color: #555;
}

.service-features li:before {
  content: '✓';
  position: absolute;
  left: 0;
  color: #FFD700;
  font-weight: bold;
}

/* Additional Services Section */
.additional-services {
  background-color: #f9f9f9;
  padding: 60px 20px;
  margin-bottom: 60px;
}

.additional-services h2 {
  text-align: center;
  font-size: 2.2rem;
  color: #222;
  margin-bottom: 40px;
  font-weight: 700;
  position: relative;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.additional-services h2:after {
  content: '';
  position: absolute;
  width: 80px;
  height: 3px;
  background-color: #FFD700;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.additional-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  max-width: 1000px;
  margin: 0 auto;
}

.additional-card {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: transform 0.3s ease;
}

.additional-card:hover {
  transform: translateY(-5px);
}

.additional-icon {
  background-color: #FFD700;
  color: #000;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.additional-card h3 {
  font-size: 1.3rem;
  margin-bottom: 15px;
  color: #222;
  font-weight: 600;
}

.additional-card p {
  color: #666;
  line-height: 1.6;
  margin: 0;
}

/* CTA Section */
.services-cta {
  background-color: #000;
  color: white;
  text-align: center;
  padding: 60px 20px;
  border-radius: 10px;
  max-width: 1200px;
  margin: 0 auto 60px;
}

.services-cta h2 {
  font-size: 2.2rem;
  color: #FFD700;
  margin-bottom: 15px;
  font-weight: 700;
}

.services-cta p {
  color: #ccc;
  margin-bottom: 30px;
  font-size: 1.1rem;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.cta-buttons {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.cta-button {
  display: inline-block;
  padding: 14px 30px;
  border-radius: 50px;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.cta-button.primary {
  background-color: #FFD700;
  color: #000;
}

.cta-button.secondary {
  background-color: transparent;
  color: #FFD700;
  border: 2px solid #FFD700;
}

.cta-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.cta-button.primary:hover {
  background-color: #fff;
  color: #000;
}

.cta-button.secondary:hover {
  background-color: #FFD700;
  color: #000;
}

/* Responsive Styles */
@media (max-width: 992px) {
  .services-grid {
    grid-template-columns: 1fr;
    gap: 30px;
  }
  
  .additional-grid {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}

@media (max-width: 768px) {
  .services-hero {
    height: 50vh;
  }
  
  .hero-content h1 {
    font-size: 2.5rem;
  }
  
  .hero-content p {
    font-size: 1.1rem;
  }
  
  .services-intro h2 {
    font-size: 1.8rem;
  }
  
  .services-intro p {
    font-size: 1rem;
  }
  
  .service-image {
    height: 200px;
  }
  
  .service-content {
    padding: 20px;
  }
  
  .service-content h3 {
    font-size: 1.3rem;
  }
  
  .services-cta h2 {
    font-size: 1.8rem;
  }
  
  .services-cta p {
    font-size: 1rem;
  }
  
  .cta-buttons {
    flex-direction: column;
    gap: 15px;
  }
  
  .cta-button {
    padding: 12px 25px;
  }
}

@media (max-width: 480px) {
  .hero-content h1 {
    font-size: 2rem;
  }
  
  .hero-content p {
    font-size: 1rem;
  }
  
  .additional-card {
    padding: 20px;
  }
}
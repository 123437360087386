.contact-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 15px 40px;
  overflow-x: hidden;
}

.contact-header {
  text-align: center;
  margin-bottom: 40px;
}

.contact-header h1 {
  font-size: 2.4rem;
  font-weight: 700;
  color: #000;
  margin-bottom: 15px;
  position: relative;
  display: inline-block;
}

.contact-header h1::after {
  content: "";
  position: absolute;
  width: 60px;
  height: 3px;
  background-color: #FFD700;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.contact-header p {
  color: #666;
  font-size: 1rem;
  max-width: 600px;
  margin: 25px auto 0;
  line-height: 1.5;
}

.contact-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin-bottom: 40px;
}

.contact-info {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  height: fit-content;
}

.contact-info-header {
  background-color: #000;
  color: #FFD700;
  padding: 20px 25px;
}

.contact-info-header h2 {
  font-size: 1.7rem;
  margin: 0;
  font-weight: 600;
}

.contact-info-content {
  padding: 20px;
}

.info-box {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.info-icon {
  min-width: 40px;
  height: 40px;
  background-color: #FFD700;
  color: #000;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
  margin-right: 15px;
}

.info-content {
  flex: 1;
  min-width: 200px;
}

.info-content h3 {
  font-size: 1.1rem;
  color: #000;
  margin-bottom: 5px;
  font-weight: 600;
}

.info-content p {
  color: #666;
  line-height: 1.5;
  margin: 0;
  font-size: 0.9rem;
  word-break: break-word;
}

.social-media {
  margin-top: 30px;
}

.social-media h3 {
  font-size: 1.1rem;
  color: #000;
  margin-bottom: 12px;
  font-weight: 600;
}

.social-icons {
  display: flex;
  gap: 12px;
}

.social-icons a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background-color: #f0f0f0;
  color: #000;
  border-radius: 50%;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  text-decoration: none;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.08);
}

.social-icons a:hover {
  background-color: #FFD700;
  color: #000;
  transform: translateY(-3px);
}

.contact-form-container {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

.contact-form-header {
  background-color: #000;
  color: #FFD700;
  padding: 20px 25px;
}

.contact-form-header h2 {
  font-size: 1.7rem;
  margin: 0;
  font-weight: 600;
}

.contact-form-content {
  padding: 25px;
}

.status-message {
  padding: 12px 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 0.9rem;
}

.status-message.success {
  background-color: #d4edda;
  color: #155724;
  border-left: 4px solid #28a745;
}

.status-message.error {
  background-color: #f8d7da;
  color: #721c24;
  border-left: 4px solid #dc3545;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.form-row {
  display: flex;
  gap: 15px;
}

.form-group {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.form-group label {
  font-weight: 500;
  color: #444;
  font-size: 0.95rem;
}

.required {
  color: #e74c3c;
}

.form-group input,
.form-group textarea {
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 0.95rem;
  transition: all 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #FFD700;
  box-shadow: 0 0 0 2px rgba(255, 215, 0, 0.15);
  outline: none;
}

.error-input {
  border-color: #e74c3c !important;
  background-color: rgba(231, 76, 60, 0.05);
}

.error {
  color: #e74c3c;
  font-size: 0.8rem;
  margin-top: -2px;
}

.submit-btn {
  background-color: #000;
  color: #FFD700;
  padding: 12px 25px;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 8px;
  align-self: flex-start;
}

.submit-btn:hover {
  background-color: #FFD700;
  color: #000;
  transform: translateY(-3px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.12);
}

.submit-btn:disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.map-container {
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  height: 350px;
}

.map-container iframe {
  height: 100%;
}

@media (max-width: 992px) {
  .contact-container {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}

@media (max-width: 768px) {
  .contact-page {
    padding: 15px 10px 30px;
  }
  
  .contact-header h1 {
    font-size: 2rem;
  }
  
  .form-row {
    flex-direction: column;
    gap: 15px;
  }
  
  .contact-form-header,
  .contact-info-header {
    padding: 15px 20px;
  }
  
  .contact-form-content,
  .contact-info-content {
    padding: 15px;
  }
  
  .submit-btn {
    width: 100%;
    align-self: center;
  }
  
  .map-container {
    height: 300px;
  }

  .info-box {
    margin-bottom: 15px;
  }

  .info-icon {
    min-width: 35px;
    height: 35px;
    font-size: 1rem;
    margin-right: 12px;
  }

  .info-content h3 {
    font-size: 1rem;
  }

  .info-content p {
    font-size: 0.85rem;
  }
}

@media (max-width: 480px) {
  .contact-page {
    padding: 10px 8px 20px;
  }

  .contact-header h1 {
    font-size: 1.8rem;
  }

  .contact-info-header h2,
  .contact-form-header h2 {
    font-size: 1.4rem;
  }

  .contact-info-content {
    padding: 12px;
  }

  .info-box {
    margin-bottom: 12px;
  }

  .info-icon {
    min-width: 30px;
    height: 30px;
    font-size: 0.9rem;
    margin-right: 10px;
  }

  .info-content {
    min-width: calc(100% - 40px);
  }

  .info-content h3 {
    font-size: 0.95rem;
    margin-bottom: 3px;
  }

  .info-content p {
    font-size: 0.8rem;
  }

  .social-media {
    margin-top: 20px;
  }

  .social-media h3 {
    font-size: 0.95rem;
    margin-bottom: 10px;
  }

  .social-icons a {
    width: 32px;
    height: 32px;
    font-size: 1rem;
  }
}

/* Add styles for business hours */
.business-hours {
  margin-top: 15px;
}

.business-hours h3 {
  font-size: 1.1rem;
  color: #000;
  margin-bottom: 10px;
  font-weight: 600;
}

.hours-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.hours-list li {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  color: #666;
  font-size: 0.9rem;
}

@media (max-width: 480px) {
  .business-hours h3 {
    font-size: 0.95rem;
  }

  .hours-list li {
    font-size: 0.8rem;
    flex-direction: column;
  }
}
/* Reset and Global Styles */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: #f8f9fa;
  color: #343a40;
  line-height: 1.6;
}

/* Hero Section */
.hero {
  background-color: #000;
  height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  position: relative;
  overflow: hidden;
}

.hero-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

/* Add a dark overlay for better text readability */
.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0,0,0,0.7), rgba(0,0,0,0.8));
  z-index: 1;
}

.hero-content {
  z-index: 2;
  width: 100%;
  max-width: 800px;
  padding: 0 20px;
  animation: fadeIn 1.5s ease-out;
  text-align: center;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

.hero-title {
  font-size: 3.5rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 15px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  line-height: 1.2;
}

.hero-subtitle {
  font-size: 1.3rem;
  opacity: 0.9;
  margin-bottom: 30px;
  font-weight: 300;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  width: 100%;
  text-align: center;
  display: block;
}

.hero-btn {
  background-color: transparent;
  color: white;
  padding: 12px 28px;
  font-size: 1.1rem;
  border-radius: 50px;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.4s ease;
  border: 2px solid #FFD700;
  font-weight: 600;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.hero-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #FFD700;
  transition: all 0.4s ease;
  z-index: -1;
}

.hero-btn:hover {
  color: #000;
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.hero-btn:hover::before {
  width: 100%;
}

/* Features Section */
.features {
  padding: 70px 20px;
  background-color: #fff;
  text-align: center;
}

.features-title {
  font-size: 2.4rem;
  margin-bottom: 30px;
  font-weight: 700;
  color: #333;
  position: relative;
  display: inline-block;
}

.features-title::after {
  content: "";
  position: absolute;
  width: 70px;
  height: 3px;
  background-color: #FFD700;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.features-description {
  margin-bottom: 50px;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.feature-point {
  font-size: 1rem;
  margin-bottom: 12px;
  color: #444;
  line-height: 1.5;
  text-align: left;
  padding-left: 15px;
  display: flex;
  align-items: flex-start;
}

.check-icon {
  color: #FFD700;
  background-color: #000;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  font-size: 0.9rem;
  flex-shrink: 0;
}

.feature-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px;
  max-width: 1200px;
  margin: 0 auto;
}

.feature-card {
  background-color: #fff;
  padding: 0;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.05);
  transition: transform 0.4s ease, box-shadow 0.4s ease;
  overflow: hidden;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.feature-image-container {
  height: 200px;
  overflow: hidden;
  position: relative;
}

.feature-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.feature-icon {
  position: absolute;
  right: 20px;
  top: 180px;
  width: 50px;
  height: 50px;
  background-color: #FFD700;
  color: #000;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.feature-card h3 {
  font-size: 1.4rem;
  margin: 36px 20px 12px;
  font-weight: 600;
  color: #222;
  position: relative;
}

.feature-card p {
  color: #666;
  line-height: 1.5;
  font-size: 0.95rem;
  padding: 0 20px 20px;
  margin: 0;
}

.feature-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.feature-card:hover .feature-image {
  transform: scale(1.05);
}

.feature-card:hover .feature-icon {
  transform: rotate(360deg);
}

/* CTA Section */
.cta {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  background: linear-gradient(135deg, #000000 0%, #1a1a1a 100%);
  color: #ffffff;
  padding: 3.5rem 2rem;
  border-radius: 15px;
  margin: 4rem auto;
  max-width: 1200px;
  text-align: center;
  position: relative;
  overflow: hidden;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
}

/* Add decorative elements */
.cta::before {
  content: '';
  position: absolute;
  top: -50px;
  left: -50px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: rgba(255, 215, 0, 0.1);
}

.cta::after {
  content: '';
  position: absolute;
  bottom: -50px;
  right: -50px;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: rgba(255, 215, 0, 0.1);
}

.cta h2 {
  font-size: 2.2rem;
  margin: 0;
  font-weight: 700;
  text-transform: capitalize;
  position: relative;
  z-index: 2;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  color: #FFD700;
}

.cta p {
  color: #ccc;
  font-size: 1rem;
  max-width: 600px;
  margin: 0;
  position: relative;
  z-index: 2;
}

.cta-btn {
  background-color: #FFD700;
  color: #000000;
  padding: 12px 25px;
  border: none;
  border-radius: 50px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.4s ease;
  margin-top: 0.5rem;
  position: relative;
  z-index: 2;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.cta-btn:hover {
  background-color: #ffffff;
  color: #000000;
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.25);
}

@media (max-width: 768px) {
  .hero {
    height: 70vh;
  }

  .hero-title {
    font-size: 2.5rem;
  }

  .hero-subtitle {
    font-size: 1.1rem;
  }

  .hero-btn {
    padding: 10px 20px;
    font-size: 1rem;
  }

  .features {
    padding: 50px 15px;
  }
  
  .features-title {
    font-size: 2rem;
  }

  .feature-cards {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  .cta {
    padding: 2.5rem 1.5rem;
    margin: 3rem auto;
  }
  
  .cta h2 {
    font-size: 1.8rem;
  }

  .cta p {
    font-size: 0.9rem;
  }
  
  .cta-btn {
    padding: 10px 20px;
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .hero-title {
    font-size: 2rem;
  }

  .hero-subtitle {
    font-size: 1rem;
  }

  .features-title {
    font-size: 1.8rem;
  }

  .feature-cards {
    grid-template-columns: 1fr;
  }

  .cta h2 {
    font-size: 1.6rem;
  }
}

/* Footer Styles */
.footer {
  background-color: #111111;
  color: #ffffff;
  padding: 30px 0 15px;
  margin-top: 40px;
}

.footer-content {
  display: grid;
  grid-template-columns: 1.2fr 1fr 0.8fr;
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-section {
  margin-bottom: 15px;
}

.footer-title {
  color: #FFD700;
  font-size: 1.1rem;
  margin-bottom: 12px;
  font-weight: 600;
  position: relative;
  padding-bottom: 8px;
}

.footer-title::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 30px;
  height: 2px;
  background-color: #FFD700;
}

.footer-description {
  line-height: 1.4;
  margin-bottom: 15px;
  color: #bbbbbb;
  font-size: 0.85rem;
}

.social-icons {
  display: flex;
  gap: 10px;
}

.social-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: #222222;
  border-radius: 50%;
  color: #FFD700;
  transition: all 0.3s ease;
  text-decoration: none;
  font-size: 0.85rem;
}

.social-icon:hover {
  background-color: #FFD700;
  color: #000000;
  transform: translateY(-3px);
}

.footer-links-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px 15px;
}

.footer-links-grid a {
  color: #bbbbbb;
  text-decoration: none;
  font-size: 0.85rem;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
}

.footer-links-grid a:hover {
  color: #FFD700;
  transform: translateX(3px);
}

.contact-info {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #bbbbbb;
  font-size: 0.85rem;
}

.contact-info.mini {
  margin-bottom: 8px;
}

.contact-info i {
  color: #FFD700;
  margin-right: 8px;
  font-size: 0.85rem;
  width: 15px;
}

.footer-bottom {
  text-align: center;
  padding-top: 15px;
  margin-top: 20px;
  border-top: 1px solid #222222;
  color: #999999;
  font-size: 0.75rem;
}

@media (max-width: 900px) {
  .footer-content {
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
}

@media (max-width: 600px) {
  .footer-content {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  
  .footer-section:first-child {
    grid-column: span 2;
  }
  
  .footer {
    padding: 25px 0 15px;
  }
  
  .footer-title {
    font-size: 1rem;
    margin-bottom: 10px;
  }
}


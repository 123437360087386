/* src/components/Menu/MenuDisplay.css */

.menu-page-container {
  max-width: 1400px;
  margin: 50px auto;
  padding: 30px;
}

.menu-header {
  text-align: center;
  margin-bottom: 40px;
}

.menu-title {
  font-size: 2.8rem;
  font-weight: 800;
  color: #000;
  margin-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
  display: inline-block;
}

.menu-title::after {
  content: "";
  position: absolute;
  width: 100px;
  height: 4px;
  background-color: #FFD700;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.menu-subtitle {
  font-size: 1rem;
  color: #666;
  max-width: 700px;
  margin: 20px auto 0;
  line-height: 1.6;
}

.menu-filter {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  margin-bottom: 50px;
}

.filter-btn {
  background-color: transparent;
  border: 2px solid #FFD700;
  color: #000;
  padding: 8px 16px;
  font-size: 0.9rem;
  font-weight: 600;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.filter-btn:hover {
  background-color: rgba(255, 215, 0, 0.2);
  transform: translateY(-2px);
}

.filter-btn.active {
  background-color: #FFD700;
  color: #000;
}

.menu-content {
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.menu-section {
  margin-bottom: 20px;
}

.menu-category {
  font-size: 1.8rem;
  margin-bottom: 25px;
  color: #000;
  position: relative;
  padding-left: 20px;
  border-left: 5px solid #FFD700;
}

.menu-items-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 25px;
}

.menu-card {
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.menu-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.12);
}

.menu-card-image {
  height: 180px;
  background-size: cover;
  background-position: center;
  transition: all 0.5s ease;
}

.menu-card:hover .menu-card-image {
  transform: scale(1.05);
}

.menu-card-content {
  padding: 16px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.menu-card-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 12px;
}

.item-name {
  font-size: 1.2rem;
  font-weight: 600;
  color: #000;
  margin: 0;
}

.item-price {
  font-size: 1.1rem;
  font-weight: 700;
  color: #FFD700;
  background-color: #000;
  padding: 4px 10px;
  border-radius: 20px;
}

.item-description {
  color: #666;
  font-size: 0.9rem;
  line-height: 1.5;
  margin: 0;
  flex-grow: 1;
}

/* Add responsiveness */
@media (max-width: 1200px) {
  .menu-items-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}

@media (max-width: 768px) {
  .menu-page-container {
    margin: 30px auto;
    padding: 20px;
  }

  .menu-title {
    font-size: 2.2rem;
  }

  .menu-subtitle {
    font-size: 0.9rem;
  }

  .menu-category {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }

  .menu-items-grid {
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    gap: 20px;
  }

  .item-name {
    font-size: 1.1rem;
  }

  .item-price {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .menu-items-grid {
    grid-template-columns: 1fr;
  }

  .menu-filter {
    gap: 8px;
  }

  .filter-btn {
    padding: 6px 12px;
    font-size: 0.85rem;
  }
}

/* Navbar Styles */
.navbar {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #000000;
  padding: 15px 30px;
  color: #FFD700;
  font-family: Arial, sans-serif;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.navbar .logo {
  font-size: 2rem; /* Adjusted logo size */
  font-weight: bold;
  color: #FFD700;
  text-transform: uppercase;
  text-decoration: none;
  margin-bottom: 10px;
}

/* Navigation Menu Styles */
.navbar .menu {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  align-items: center;
}

/* Navbar Links */
.navbar .menu a {
  text-decoration: none;
  color: #FFD700;
  font-size: 1rem;
  font-weight: 500;
  padding: 10px;
  transition: background-color 0.3s ease;
  border-radius: 5px;
  width: 100%;
  text-align: center;
}

/* Hover Effect for Links */
.navbar .menu a:hover {
  background-color: #000000;
  color: white;
  border: 1px solid #FFD700;
}

/* Active Link Style */
.navbar .menu a.active {
  background-color: #FFD700;
  color: #000000;
}

/* Search Container Styles */
.navbar .search-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Search Input Styles */
.navbar .search-container input {
  padding: 8px 15px;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #FFD700;
  outline: none;
  background-color: #000000;
  color: #FFD700;
}

/* Search Input Focus Style */
.navbar .search-container input:focus {
  border-color: #FFD700;
}

/* Search Button Styles */
.navbar .search-container button {
  background-color: #FFD700;
  color: #000000;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

/* Search Button Hover Effect */
.navbar .search-container button:hover {
  background-color: #000000;
  color: #FFD700;
  border: 1px solid #FFD700;
}

/* Hamburger Menu Icon */
.navbar-toggle {
  display: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #FFD700;
}

.navbar-toggle .menu-icon {
  width: 25px;
  height: 2px;
  background-color: #FFD700;
  display: block;
  position: relative;
}

.navbar-toggle .menu-icon::before,
.navbar-toggle .menu-icon::after {
  content: '';
  width: 25px;
  height: 2px;
  background-color: #FFD700;
  display: block;
  position: absolute;
  left: 0;
}

.navbar-toggle .menu-icon::before {
  top: -8px;
}

.navbar-toggle .menu-icon::after {
  top: 8px;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .navbar {
    padding: 10px 20px; /* Adjust padding for smaller screens */
  }

  .navbar .logo {
    font-size: 1.5rem; /* Adjusted font size for mobile */
    text-align: center; /* Center logo on mobile */
    padding: 10px 0; /* Padding for better spacing */
  }

  .navbar .menu {
    gap: 10px; /* Adjust space between items */
  }

  .navbar .menu a {
    padding: 8px 15px; /* Adjust padding for mobile */
    font-size: 0.9rem; /* Adjust font size for mobile */
  }
}

/* Container for the entire navbar */
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 15px 5%;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
  transition: all 0.3s ease;
}

/* Style for the header (website name/logo) */
.navbar-header {
  width: auto;
  text-align: left;
  margin-bottom: 0;
}

.navbar-header h1 {
  margin: 0;
  color: #FFD700;
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.navbar-header h1:hover {
  transform: scale(1.05);
}

/* Style for the navigation menu */
.navbar-menu ul {
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.navbar-menu ul li {
  margin: 0 12px;
  position: relative;
}

.navbar-menu ul li a {
  color: #FFD700;
  text-decoration: none;
  font-size: 1.05rem;
  font-weight: 500;
  padding: 8px 5px;
  position: relative;
  transition: all 0.3s ease;
}

/* Create underline animation for menu items */
.navbar-menu ul li a::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  background-color: #ffffff;
  bottom: 0;
  left: 0;
  transition: width 0.3s ease;
}

.navbar-menu ul li a:hover {
  color: #ffffff;
}

.navbar-menu ul li a:hover::after {
  width: 100%;
}

/* Add mobile responsiveness */
@media (max-width: 768px) {
  nav {
    flex-direction: column;
    padding: 10px 20px;
  }
  
  .navbar-header {
    width: 100%;
    text-align: center;
    margin-bottom: 12px;
  }
  
  .navbar-menu ul {
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .navbar-menu ul li {
    margin: 5px 8px;
  }
}


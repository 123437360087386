/* Gallery Page Styles */
.gallery-container {
  max-width: 1200px;
  margin: 50px auto;
  padding: 0 20px;
  font-family: 'Poppins', sans-serif;
}

/* Gallery Header */
.gallery-header {
  text-align: center;
  margin-bottom: 40px;
}

.gallery-header h1 {
  font-size: 2.8rem;
  font-weight: 700;
  color: #222;
  margin-bottom: 15px;
  position: relative;
  display: inline-block;
}

.gallery-header h1::after {
  content: "";
  position: absolute;
  width: 60px;
  height: 3px;
  background-color: #FFD700;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.gallery-header p {
  color: #666;
  font-size: 1.1rem;
  max-width: 600px;
  margin: 20px auto 0;
}

/* Gallery Filter Buttons */
.gallery-filter {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  margin-bottom: 40px;
}

.filter-btn {
  background-color: transparent;
  border: 2px solid #FFD700;
  color: #000;
  padding: 8px 20px;
  font-size: 0.95rem;
  font-weight: 600;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.filter-btn:hover {
  background-color: rgba(255, 215, 0, 0.2);
  transform: translateY(-2px);
}

.filter-btn.active {
  background-color: #FFD700;
  color: #000;
}

/* Gallery Grid */
.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
}

.gallery-item {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  aspect-ratio: 1 / 1;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.gallery-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
  display: block;
}

.gallery-item:hover img {
  transform: scale(1.1);
}

/* Overlay Styles */
.gallery-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 60%);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 20px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.gallery-item:hover .gallery-overlay {
  opacity: 1;
}

.overlay-content {
  text-align: center;
  color: white;
  transform: translateY(20px);
  transition: transform 0.3s ease;
  width: 100%;
}

.gallery-item:hover .overlay-content {
  transform: translateY(0);
}

.overlay-content h3 {
  font-size: 1.2rem;
  margin-bottom: 8px;
  font-weight: 600;
}

.category-tag {
  display: inline-block;
  background-color: rgba(255, 215, 0, 0.7);
  color: #000;
  padding: 4px 10px;
  border-radius: 20px;
  font-size: 0.8rem;
  font-weight: 600;
}

/* Responsive Styles */
@media (max-width: 992px) {
  .gallery-grid {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  }
}

@media (max-width: 768px) {
  .gallery-container {
    margin: 30px auto;
  }
  
  .gallery-header h1 {
    font-size: 2.2rem;
  }
  
  .gallery-header p {
    font-size: 1rem;
  }
  
  .gallery-grid {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 15px;
  }
  
  .filter-btn {
    padding: 6px 15px;
    font-size: 0.85rem;
  }
  
  .overlay-content h3 {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .gallery-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  
  .gallery-filter {
    gap: 10px;
  }
  
  .filter-btn {
    padding: 5px 12px;
    font-size: 0.8rem;
  }
}
.booking-container {
  max-width: 900px;
  margin: 40px auto 60px;
  padding: 35px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.06);
}

.booking-header {
  text-align: center;
  margin-bottom: 35px;
}

.booking-header h2 {
  font-size: 2.2rem;
  color: #000;
  margin-bottom: 12px;
  font-weight: 700;
  position: relative;
  display: inline-block;
}

.booking-header h2::after {
  content: "";
  position: absolute;
  width: 60px;
  height: 3px;
  background-color: #FFD700;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
}

.booking-header p {
  color: #666;
  font-size: 0.95rem;
  max-width: 650px;
  margin: 0 auto;
  line-height: 1.5;
}

.booking-form {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.form-section {
  padding: 22px;
  background-color: #f9f9f9;
  border-radius: 8px;
  border-left: 4px solid #FFD700;
}

.form-section h3 {
  font-size: 1.3rem;
  color: #222;
  margin-bottom: 18px;
  padding-bottom: 8px;
  border-bottom: 1px solid #eee;
}

.section-description {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 18px;
}

.form-row {
  display: flex;
  gap: 18px;
  margin-bottom: 18px;
}

.form-group {
  flex: 1;
  margin-bottom: 18px;
}

.form-row .form-group {
  margin-bottom: 0;
}

.form-group label {
  display: block;
  margin-bottom: 6px;
  font-weight: 500;
  color: #333;
  font-size: 0.95rem;
}

.required {
  color: #e74c3c;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 10px 12px;
  border-radius: 6px;
  border: 1px solid #ddd;
  font-size: 0.95rem;
  transition: all 0.3s ease;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  border-color: #FFD700;
  box-shadow: 0 0 0 2px rgba(255, 215, 0, 0.15);
  outline: none;
}

.error-input {
  border-color: #e74c3c !important;
  background-color: rgba(231, 76, 60, 0.05);
}

.error {
  display: block;
  color: #e74c3c;
  font-size: 0.8rem;
  margin-top: 4px;
}

.server-error {
  background-color: #fdeaea;
  border-left: 4px solid #e74c3c;
  padding: 12px;
  margin-bottom: 25px;
  border-radius: 6px;
  font-size: 0.9rem;
}

.server-error p {
  color: #c0392b;
  margin: 0;
}

.menu-selection {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 12px;
}

.menu-option {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 10px 12px;
  border-radius: 6px;
  border: 1px solid #eee;
  transition: all 0.3s ease;
}

.menu-option:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  border-color: #ddd;
}

.menu-option input {
  margin-right: 8px;
  width: 16px;
  height: 16px;
  accent-color: #FFD700;
}

.menu-option label {
  font-size: 0.9rem;
  margin: 0;
}

.submit-btn {
  background-color: #000;
  color: #FFD700;
  padding: 12px 25px;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 10px;
  align-self: center;
}

.submit-btn:hover {
  background-color: #FFD700;
  color: #000;
  transform: translateY(-3px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.12);
}

.submit-btn:disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

@media (max-width: 768px) {
  .booking-container {
    margin: 30px 15px;
    padding: 25px 20px;
  }

  .booking-header h2 {
    font-size: 1.8rem;
  }

  .form-row {
    flex-direction: column;
    gap: 15px;
  }

  .menu-selection {
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  }

  .submit-btn {
    width: 100%;
  }
}
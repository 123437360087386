/* About Page Styles */
.about-container {
  font-family: 'Poppins', sans-serif;
  color: #333;
}

/* Hero Section */
.about-hero {
  position: relative;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  background-image: url('https://images.unsplash.com/photo-1555396273-367ea4eb4db5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80');
  background-size: cover;
  background-position: center;
  margin-bottom: 60px;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.7));
}

.hero-content {
  position: relative;
  z-index: 1;
  max-width: 800px;
  padding: 0 20px;
}

.hero-content h1 {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.hero-content p {
  font-size: 1.3rem;
  opacity: 0.9;
  margin: 0;
}

/* Section Common Styles */
.about-section {
  padding: 60px 20px;
  max-width: 1200px;
  margin: 0 auto 60px;
}

.section-subtitle {
  display: block;
  color: #FFD700;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.section-title {
  font-size: 2.2rem;
  font-weight: 700;
  margin-bottom: 30px;
  position: relative;
  color: #222;
}

.section-title::after {
  content: '';
  display: block;
  width: 70px;
  height: 3px;
  background-color: #FFD700;
  margin-top: 15px;
}

/* Intro Section */
.intro-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  align-items: center;
}

.intro-content {
  padding-right: 20px;
}

.intro-content p {
  margin-bottom: 15px;
  line-height: 1.6;
  color: #555;
}

.intro-image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

/* Features Section */
.features-section {
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 60px 40px;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  margin-top: 40px;
}

.feature-card {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

.feature-icon {
  background-color: #FFD700;
  color: #000;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  margin-bottom: 20px;
}

.feature-card h3 {
  font-size: 1.3rem;
  margin-bottom: 15px;
  font-weight: 600;
  color: #222;
}

.feature-card p {
  color: #666;
  margin: 0;
  line-height: 1.6;
}

/* Journey Section */
.journey-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  align-items: center;
}

.journey-image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.timeline {
  position: relative;
  padding-left: 30px;
  margin-top: 30px;
}

.timeline::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 2px;
  height: 100%;
  background-color: #FFD700;
}

.timeline-item {
  position: relative;
  margin-bottom: 30px;
}

.timeline-marker {
  position: absolute;
  left: -38px;
  top: 5px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #FFD700;
  border: 3px solid white;
  box-shadow: 0 0 0 2px #FFD700;
}

.timeline-content h3 {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 5px;
  color: #FFD700;
}

.timeline-content p {
  margin: 0;
  color: #666;
  line-height: 1.6;
}

/* Team Section */
.team-section {
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 60px 40px;
}

.team-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 40px;
}

.team-card {
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;
}

.team-card:hover {
  transform: translateY(-5px);
}

.team-image {
  width: 100%;
  height: 300px;
  overflow: hidden;
  position: relative;
}

.team-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.team-card:hover .team-image img {
  transform: scale(1.05);
}

.team-info {
  padding: 20px;
}

.team-info h3 {
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 5px;
  color: #222;
}

.team-role {
  color: #FFD700;
  font-weight: 500;
  margin-bottom: 15px;
  font-size: 0.95rem;
}

.team-bio {
  color: #666;
  margin: 0;
  line-height: 1.5;
  font-size: 0.95rem;
}

/* CTA Section */
.cta-section {
  background-color: #000;
  color: white;
  border-radius: 10px;
  text-align: center;
  padding: 60px 20px;
  margin: 0 15px 60px;
  width: auto;
  max-width: calc(100% - 30px);
}

.cta-section h2 {
  color: #FFD700;
  font-size: 2.2rem;
  margin-bottom: 20px;
  word-wrap: break-word;
  line-height: 1.2;
}

.cta-section p {
  color: #ccc;
  margin-bottom: 30px;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 10px;
  font-size: 1rem;
  line-height: 1.5;
}

.cta-button {
  display: inline-block;
  background-color: #FFD700;
  color: #000;
  padding: 14px 30px;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 50px;
  text-decoration: none;
  transition: all 0.3s ease;
}

.cta-button:hover {
  background-color: white;
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

/* Responsive Styles */
@media (max-width: 992px) {
  .intro-grid, .journey-grid {
    grid-template-columns: 1fr;
    gap: 30px;
  }
  
  .intro-content {
    padding-right: 0;
    text-align: center;
  }
  
  .section-title::after {
    margin: 15px auto 0;
  }
  
  .team-grid {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  }
}

@media (max-width: 768px) {
  .about-hero {
    height: 50vh;
  }
  
  .hero-content h1 {
    font-size: 2.5rem;
  }
  
  .hero-content p {
    font-size: 1.1rem;
  }
  
  .about-section {
    padding: 40px 15px;
    margin-bottom: 40px;
  }
  
  .section-title {
    font-size: 1.8rem;
  }
  
  .intro-content p {
    font-size: 0.95rem;
  }
  
  .feature-card, .team-card {
    padding: 20px;
  }
  
  .team-image {
    height: 250px;
  }

  .cta-section {
    padding: 40px 15px;
    margin: 0 10px 40px;
  }

  .cta-section h2 {
    font-size: 1.8rem;
    margin-bottom: 15px;
  }

  .cta-section p {
    font-size: 0.95rem;
    margin-bottom: 25px;
  }

  .cta-button {
    padding: 12px 25px;
    font-size: 0.95rem;
  }
}

@media (max-width: 480px) {
  .hero-content h1 {
    font-size: 2rem;
  }
  
  .hero-content p {
    font-size: 1rem;
  }
  
  .section-subtitle {
    font-size: 0.9rem;
  }
  
  .section-title {
    font-size: 1.6rem;
  }
  
  .feature-card {
    padding: 15px;
  }
  
  .team-image {
    height: 200px;
  }
  
  .team-info {
    padding: 15px;
  }
  
  .team-info h3 {
    font-size: 1.2rem;
  }
  
  .team-role {
    font-size: 0.9rem;
  }
  
  .team-bio {
    font-size: 0.9rem;
  }

  .cta-section {
    padding: 30px 15px;
    margin: 0 5px 30px;
  }

  .cta-section h2 {
    font-size: 1.5rem;
    margin-bottom: 12px;
  }

  .cta-section p {
    font-size: 0.9rem;
    margin-bottom: 20px;
  }

  .cta-button {
    padding: 10px 20px;
    font-size: 0.9rem;
    width: auto;
    max-width: 90%;
  }
}